import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import GWlp2page from "src/components/gwlp2page"
import ChunkyFooter from "src/components/chunkyfooter"
import CtaDisclosureCb2 from "src/components/ctadisclosurecb2"
import Disclaimers from "@tightrope/disclaimers"

const GWlpData = import("./data/data.json");
const footerData = import("./data/footerData.json");

export default function prdbest() {

  return(
    <HomepageLayout>
    <Helmet>
    <title>Easy Print | Print from Anywhere</title>
    <style type="text/css">{`
       #ctadisclosurecb2-module--ctadisclaimer {
        margin:0 auto !important;
        font-size:13px;
        width:500px;
       }

       #ctadisclosurecb2-module--ctadisclaimer p {
        font-size:12px;
        line-height:125%;
       }
        #disclaimers-module--disclaimers {
        width:100%;
        }
  
       #disclaimers-module--disclaimers p {
        font-weight: 300;
        color: #333;
        font-size: 13px;
        text-align: center;
        font-family: Roboto,sans-serif;
        max-width: 580px;
        margin: 0 auto;
        }
  
        #disclaimers-module--disclaimers p a {
        font-weight:700;
        }

    `}
    </style>
    </Helmet>
    <section>
      <GWlp2page data={GWlpData}></GWlp2page>
      <CtaDisclosureCb2></CtaDisclosureCb2>
      <ChunkyFooter data={footerData}><Disclaimers language='en-safari-new'></Disclaimers></ChunkyFooter>
      </section>
    </HomepageLayout>

    
  )
  }
